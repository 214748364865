<template>
  <div class="container" v-if="data.id">

    <!-- 支付状态 -->
    <div class="trade-status-line">
      <div class="trade-status" :style="{ color: tradeStatusMap[data.tradeStatus].color }">
        <a-icon :type="tradeStatusMap[data.tradeStatus].icon" style="margin-right: 10px"></a-icon>
        <div>订单{{tradeStatusMap[data.tradeStatus].text}}{{data.tradeStatus === 'Pending' ? '，请扫码支付' : ''}}</div>
      </div>

      <a-button v-if="data.tradeStatus === 'Pending'" @click="handleCancelOrder">
        取消订单
      </a-button>
    </div>

    <!-- 二维码 -->
    <div class="qrcode-block" v-if="data.tradeStatus === 'Pending' && payUrl !== ''">
      <qrcode-vue class="qrcode" :value="payUrl" :size="300" level="H"></qrcode-vue>
    </div>

    <!-- 收货地址栏 -->
    <div class="address-bar">
      <div>
        <div class='contact-info'>
          <span>{{data.username}}</span>
          <span class='phone-number'>{{data.phoneNumber}}</span>
        </div>
        <div class='address-line'>
          <div class="address">{{data.region}} {{data.address}}</div>
        </div>
      </div>
    </div>

    <!-- 石材信息 -->
    <div class='stone-info-block'>
      <img alt="image" class="stone-image"
           v-if="data.orderDetail[0].mainImage" :src='data.orderDetail[0].mainImage + "?x-oss-process=image/resize,w_80,m_lfit"' />

      <div class="stone-info">
        <div class="stone-title">{{ data.orderDetail[0].title }}</div>
        <div class="stone-info-detail">
          <div class="stone-count">×{{ data.orderDetail[0].count }}</div>
          <div class="total-price">￥{{ data.totalPrice }}</div>
        </div>
      </div>

      <div>
        <div class="used-token-line">
          使用代币<span>{{ data.usedToken }}</span>
        </div>
        <div class="price-line">
          实付款<span class="price" style="font-size: 15px">￥</span><span class="price">{{ data.paidPrice }}</span>
        </div>
      </div>
    </div>

    <!-- 订单信息 -->
    <div class="order-block">
      <div>
        <div>订单号</div>
        <div>{{ data.orderNo }}</div>
      </div>
      <div>
        <div>创建时间</div>
        <div>{{ data.createdAt }}</div>
      </div>
      <div v-if="data.tradeStatus === 'Paid'">
        <div>支付时间</div>
        <div>{{ data.paidAt }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import moment from 'moment'
import status from '@/mixins/status'

export default {
  name: 'OrderDetail',

  data () {
    return {
      // 订单数据
      data: {},
      // 扫码支付url
      payUrl: ''
    }
  },

  props: {
    orderID: String
  },

  mixins: [status],

  components: { QrcodeVue },

  beforeMount () {
    this.getOrder()
  },

  methods: {
    // 获取数据
    getOrder () {
      this.api.getOrderByUser(this.orderID).then(res => {
        this.data = res.data.getOrderByUser
        this.data.createdAt = moment(this.data.createdAt).format('YYYY-MM-DD HH:mm:ss')
        if (this.data.paidAt !== '' && this.data.paidAt !== null && this.data.paidAt !== undefined) {
          this.data.paidAt = moment(this.data.paidAt).format('YYYY-MM-DD HH:mm:ss')
        }
        if (this.data.tradeStatus === 'Pending') {
          this.getPaymentNative(this.data.id)
        }
      })
    },

    // 获取支付二维码
    getPaymentNative (id) {
      this.api.getPaymentNative({ orderID: id }).then(res => {
        if (res.data.getPaymentNative.codeUrl) {
          this.payUrl = res.data.getPaymentNative.codeUrl
        }
      })
    },

    // 取消订单
    handleCancelOrder () {
      this.api.cancelOrderByUser(this.data.id).then(res => {
        try {
          const c = res.data.cancelOrderByUser.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('取消成功')
            this.getOrder()
            return
          }
          this.$message.error('取消失败')
        } catch (e) {
          this.$message.error('取消失败')
        }
      })
    }
  }
}
</script>

<style scoped>
.container {
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 20px;
}

.trade-status-line {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}
.trade-status {
  display: flex;
  align-items: center;
  font-size: 23px;
}

.qrcode-block {
  display: flex;
  justify-content: center;
}
.qrcode {
  padding: 20px;
  width: 340px;
  background-color: #fff;
  border-radius: 10px;
}

.address-bar {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px;
  cursor: pointer;
}
.address-bar > div {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
.address-line {
  display: flex;
  align-items: center;
}
.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone-number {
  margin-left: 15px;
  color: #61687c;
}

.stone-info-block {
  display: grid;
  grid-template-columns: 80px 300px auto;
  row-gap: 5px;
  background-color: #fff;
  padding: 20px;
}
.stone-info-block:hover {
  box-shadow: 0 0 5px 1px rgba(111, 111, 111, 0.1);
}
.stone-info-block > div:first-child {
  display: flex;
  align-items: center;
}
.stone-image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.stone-info {
  margin-left: 20px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
}
.stone-title {
  max-width: 300px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stone-info-detail {
  display: flex;
  font-size: 14px;
  color: #666;
}
.total-price {
  margin-left: 5px;
}

.used-token-line, .price-line {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.used-token-line span {
  margin-left: 5px;
  font-weight: bold;
  font-size: 18px;
}

.price {
  color: var(--red-color);
  font-size: 20px;
  font-weight: bolder;
}

.order-block {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px;
  row-gap: 5px;
}
.order-block:hover {
  box-shadow: 0 0 5px 1px rgba(111, 111, 111, 0.1);
}
.order-block > div {
  display: flex;
}
.order-block > div > div:first-child {
  width: 100px;
}
</style>
